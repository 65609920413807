import React, { useState, useEffect, useRef } from 'react';
import './Dashboard.css';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useLocation } from 'react-router-dom';
import { NavigationControl, FullscreenControl } from 'maplibre-gl';
import xmljs from 'xml-js';
import { LngLatBounds } from 'maplibre-gl';
import { toast } from 'react-toastify';
// import { ScaleControl} from 'maplibre-gl';
import delhiData from '../wmslayers/delhi.json';
import mumbaiData from '../wmslayers/mumbai.json';
import layericon from '../images/icons/fa-solid--layer-group.png';

import street from '../images/Basemaps/streets-v2.png'
import satellite from '../images/Basemaps/satellite.png'
import no_basemap from '../images/Basemaps/No Basemap.png'

// icons for cursor
import PalmIcon from '../images/icons/palm.png'
import InfoIcon from '../images/icons/information.png'
//icon for clear layers
import ClearLayerIcon from '../images/icons/clear-layers.png'
 //arrow icons
 import LeftArrow from '../images/icons/left-arrow.png'
 import RightArrow from '../images/icons/right-arrow.png'
// Define the custom ratio scale control






function Dashboard() {
  const [map, setMap] = useState(null);
  const [selectedLayers, setSelectedLayers] = useState([]);
  const [selectedAerodrome, setSelectedAerodrome] = useState(null);
  const [expandedLayers, setExpandedLayers] = useState({});
  const [expandedSubLayers, setExpandedSubLayers] = useState({});
  const [latDeg, setLatDeg] = useState('');
  const [latMin, setLatMin] = useState('');
  const [latSec, setLatSec] = useState('');
  const [lonDeg, setLonDeg] = useState('');
  const [lonMin, setLonMin] = useState('');
  const [lonSec, setLonSec] = useState('');
  const [showSearchForm, setShowSearchForm] = useState(false);
  const [wmsLayerIds, setWmsLayerIds] = useState([]);
  const [currentMarker, setCurrentMarker] = useState(null);
  const [aerodrome, setAerodrome] = useState(null);
  const [queryLayers, setQueryLayers] = useState(null);

  const mapPanelRef = useRef(null);
  const location = useLocation();

  const [style, setStyle] = useState('/my-style.json')
  const [activeBaselayer, setActiveBaselayer] = useState('satellite');


  // icon button active
  const [activeButton, setActiveButton] = useState('pan'); // State to track active button
  const [checkedLayers, setCheckedLayers] = useState({});
  const [checkedLayersValue, setCheckedLayersValue ] = useState({});
 
  const [showLayerPanel, setShowLayerPanel] = useState(true);
  const [hoverlat, setHoverLat] = useState(null)
  const [hoverlng, setHoverLng] = useState(null)


  const toggleLayerPanel = () => {
    setShowLayerPanel(!showLayerPanel);
  };


  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
   // Update style of .map-panel canvas based on active button
   const mapPanelCanvas = document.querySelector('.map-panel canvas');
   if (buttonType === 'info') {
     mapPanelCanvas.style.cursor = 'pointer'; // Example change for cursor style
     
   } else if(buttonType==="pan") {
     mapPanelCanvas.style.cursor = ''; // Reset cursor style if not info button
    
   }
 
  };

  useEffect(() => {
    const aerodrome = location.state?.selectedAerodrome || 'New Delhi';
    const aerodromeData = aerodrome === 'New Delhi' ? delhiData : mumbaiData;
  

    if (!map) {
        // Initialize map if not already initialized
        const initializedMap = new maplibregl.Map({
            container: mapPanelRef.current,
            style: style,
            center: aerodromeData.center,
            zoom: 12,
        });

        toast.info('Switch between the satellite map, street map, or no base map from the top right, and open or close the overlay layers from the left section.', {
            autoClose: 15000
        });

        // initializedMap.addControl(new ScaleControl(), 'bottom-right');
        initializedMap.addControl(new NavigationControl(), 'top-right');
        initializedMap.addControl(new FullscreenControl(), 'bottom-right');
       
  
        setMap(initializedMap);
        setSelectedAerodrome(aerodrome);
        setSelectedLayers(aerodromeData.wmslayers);
    } else {
        // Update map style if map is already initialized
        map.setStyle(style);
    }
    
}, [location.state, style,map]);



  const handleBaselayerToggle = (baselayer) => {
    setActiveBaselayer(baselayer);
    // wmsLayerIds.forEach((layerId) => {
     
    //   removeWmsLayer(layerId);
    // });
 
    if (baselayer === 'street') {
      
      setStyle('https://api.maptiler.com/maps/streets-v2/style.json?key=Otbh9YhFMbwux7HyoffB');
    
     
   
    } else if (baselayer === 'satellite') {
      setStyle('/my-style.json');
    }
    else if (baselayer === 'no_basemap') {
      setStyle('/no_basemap.json');
    }
    
      // Assuming wmsLayerIds is an array of WMS layer IDs you want to manage
      if (map) {
      

        map.once('styledata', () => {
            // Move WMS layers above base layers
            if (wmsLayerIds && Array.isArray(wmsLayerIds)) {
                wmsLayerIds.forEach((layerId) => {
      
                  addWmsLayer(layerId);
                  
                });
            }
        });
    }
  };

  useEffect(() => {
    if (wmsLayerIds.length > 0) {
      setQueryLayers(wmsLayerIds.join(','));
    } else {
      setQueryLayers(null);
    }
  }, [wmsLayerIds]);
  const getKeyForFeatureId = (aerodromeData, featureId) => {
    console.log(featureId)
  
    const findKeyInLayer = (layerGroup, featureId) => {
      for (const [key, value] of Object.entries(layerGroup)) {
        if (typeof value === 'object') {
          const nestedKey = findKeyInLayer(value, featureId);
          console.log(nestedKey)
          if (nestedKey) {
            return nestedKey;
          }
        } else if (value === featureId) {
          return key;
        }
      }
      return null;
    };
  
    for (const layerGroup of Object.values(aerodromeData.wmslayers)) {
     
      const result = findKeyInLayer(layerGroup, featureId);
      
      if (result) {
        return result;
      }
    }
  
    return null;
  };
  
  

  useEffect(() => {
    if (map&& activeButton==="info") {
      const handleMapClick = async (e) => {
        const width = map.getContainer().offsetWidth;
        const height =  map.getContainer().offsetHeight;
        const zoom = map.getZoom();
        const point = map.project(e.lngLat, zoom);
        const x = Math.round(point.x);
        const y = Math.round(point.y);
        const aero = aerodrome ==='IGIAerodrome' ? 'New Delhi' : 'Santa_Cruz_Aerodrome'
      
        const aerodromeData = aero === 'New Delhi' ? delhiData : mumbaiData;

        const handlePopup = (e, response) => {
          const lastFeatureIndex = response.features.length - 1;
          const feature = response.features[lastFeatureIndex];
          
          const properties = feature.properties;
             // Clean the feature.id by removing the .1 part
             
        const cleanedFeatureId = feature.id.replace(/\.\d+$/, '');
        const value = aerodrome+":" + cleanedFeatureId;

        // Get the key for cleaned feature.id
        const featureKey = getKeyForFeatureId(aerodromeData,value);
       

      
        let popupContent = `
        <div class="popup-content">
          <div class="popup-header">
            <h2>${featureKey}</h2>
          </div>
          <div class="popup-body">
            <h3>Feature Details</h3>
            <div class="popup-footer">`;
      
          Object.keys(properties).forEach((key) => {
            if (key === 'geometry' || key === 'layer') {
              return;
            }
            popupContent += `<p style="margin: 0;" ><strong>${key}:</strong> ${properties[key]}</p>`;
          });
      
          popupContent += '</div>';
      
          const coordinates = e.lngLat;
      
          new maplibregl.Popup({
            closeButton: true,
            closeOnClick: true,
            maxWidth: '400px',
          })
            .setLngLat(coordinates)
            .setHTML(popupContent)
            .addTo(map);
        };
      
        if (queryLayers) {
        
          const url = `https://www.prithvipraroop.net/geoserver/${aerodrome}/wms?service=WMS&version=1.1.1&request=GetFeatureInfo&info_format=application/json&query_layers=${queryLayers}&styles=&layers=${queryLayers}&feature_count=50&x=${x}&y=${y}&srs=EPSG%3A4326&width=${width}&height=${height}&bbox=${map.getBounds().toArray().join(',')}`;
      
          try {
            const fetchedResponse = await fetch(url);
            const fetchedData = await fetchedResponse.json();

            if (fetchedData && fetchedData.features && fetchedData.features.length > 0) {
              handlePopup(e, fetchedData);
            }
          } catch (error) {
            console.error('Error fetching feature info:', error);
          }
        }
      };

      map.on('click', handleMapClick);

      return () => {
        map.off('click', handleMapClick);
      };
    }
  }, [map, queryLayers, aerodrome,location.state?.selectedAerodrome,activeButton ]);

  const handleSelectClick = (e) => {
    setWmsLayerIds([]);
    setAerodrome(null);
    setQueryLayers(null);

    if (map) {
      wmsLayerIds.forEach((layerId) => {
        if (map.getLayer(layerId)) {
          map.removeLayer(layerId);
        }
        if (map.getSource(layerId)) {
          map.removeSource(layerId);
        }
      });
    }
 

    const value = e.target.value;
    const aerodromeData = value === 'New Delhi' ? delhiData : mumbaiData;
    const coordinates = aerodromeData.center;

    setSelectedAerodrome(value);
    setSelectedLayers(aerodromeData.wmslayers);

    if (map) {
      map.setCenter(coordinates);
        // Add the IGIAerodrome:Delhi_Terrain_Data_Set-Delhi_DEM layer
   

  
  
    
    }
  };

  const toggleLayerVisibility = (layerGroup) => {
    setExpandedLayers((prevState) => ({
      ...prevState,
      [layerGroup]: !prevState[layerGroup],
    }));
  };

  const toggleSubLayerVisibility = (layerGroup, subLayer) => {
    setExpandedSubLayers((prevState) => ({
      ...prevState,
      [layerGroup]: {
        ...prevState[layerGroup],
        [subLayer]: !prevState[layerGroup]?.[subLayer],
      },
    }));
  };

  const addWmsLayer = (layer) => {
   
    const [aerodrome] = layer.split(':');
    setAerodrome(aerodrome);

    if (map.getLayer(layer)) {
      map.removeLayer(layer);
    }

    if (map.getSource(layer)) {
      map.removeSource(layer);
    }

    let tileUrl;

    if (layer === 'Santa_Cruz_Aerodrome:Mx_satellite_imagery_updated') {
      tileUrl = `https://www.prithvipraroop.net/geoserver/${aerodrome}/wms?service=WMS&version=1.1.0&request=GetMap&layers=${layer}&bbox={bbox-epsg-3857}&transparent=true&width=256&height=256&srs=EPSG%3A3857&format=image/jpeg`;
    } else {
      tileUrl = `https://www.prithvipraroop.net/geoserver/${aerodrome}/wms?service=WMS&version=1.1.0&request=GetMap&layers=${layer}&bbox={bbox-epsg-3857}&transparent=true&width=256&height=256&srs=EPSG%3A3857&format=image/png`;
    }

    map.addLayer({
      id: `${layer}`,
      type: 'raster',
      source: {
        type: 'raster',
        tiles: [tileUrl],
        tileSize: 256,
      }
    });

    setWmsLayerIds((prevIds) => [...prevIds, layer]);
    
    
  };

  const handleShowPoint = () => {
    const convertToDecimal = (deg, min, sec) => {
      return parseFloat(deg) + parseFloat(min) / 60 + parseFloat(sec) / 3600;
    };

    const latitude = convertToDecimal(latDeg, latMin, latSec);
    const longitude = convertToDecimal(lonDeg, lonMin, lonSec);

    if (!isNaN(latitude) && !isNaN(longitude)) {
      if (currentMarker) {
        currentMarker.remove();
      }

      const newMarker = new maplibregl.Marker().setLngLat([longitude, latitude]).addTo(map);
      setCurrentMarker(newMarker);
      map.setCenter([longitude, latitude]);
      map.setZoom(13);
    } else {
      alert('Please enter valid latitude and longitude.');
    }
  };

  if(!showSearchForm ){

    if (currentMarker) {
      currentMarker.remove();
    }

  }


  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
   
    setCheckedLayers((prev) => ({
      ...prev,
      [name]: checked,
    }));

       setCheckedLayersValue((prev) => ({
      ...prev,
      [value]: checked,
    }));

    if (checked) {
      
      addWmsLayer(value);
    } else {
      removeWmsLayer(value);
    }
  };


  const removeWmsLayer = (layer) => {
    console.log(layer)
    
    if (map.getLayer(layer)) {
      map.removeLayer(layer);
    }
    if (map.getSource(layer)) {
      map.removeSource(layer);
    }
    setWmsLayerIds((prevIds) => prevIds.filter((id) => id !== layer));
  };
  const handleClearLayers = () => {
  
    
       // Uncheck all layers
    setCheckedLayers({});
    setCheckedLayersValue({});
  
    // Remove all layers
     console.log("Clicked clear layer")
       // Remove all layers
    Object.keys(checkedLayersValue).forEach((layerKey) => {
      if (checkedLayersValue[layerKey]) {
    
        removeWmsLayer(layerKey);
    
      }
    });

  };




  const ZoomToLayer = async (value) => {
    try {
      const url = 'https://www.prithvipraroop.net/geoserver/wms?service=wms&version=1.1.1&request=GetCapabilities';
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const xmlText = await response.text();
      const json = xmljs.xml2js(xmlText, { compact: true });

      const layers = json.WMT_MS_Capabilities.Capability.Layer.Layer;
      const layer = layers.find(layer => layer['Name']._text === value);

      if (!layer) {
        throw new Error(`Layer '${value}' not found in GetCapabilities response.`);
      }

      const bbox = layer['LatLonBoundingBox']._attributes;

      const bounds = new LngLatBounds(
        [parseFloat(bbox.minx), parseFloat(bbox.miny)],
        [parseFloat(bbox.maxx), parseFloat(bbox.maxy)]
      );
      if (map) {
        map.fitBounds(bounds, { padding: 50 });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const toDMS = (decimal, type) => {
    const degrees = Math.floor(decimal);
    const minutesDecimal = Math.abs(decimal - degrees) * 60;
    const minutes = Math.floor(minutesDecimal);
    const seconds = (minutesDecimal - minutes) * 60; // Keep seconds with full precision
  
    const direction = type === 'lat' ? (decimal >= 0 ? 'N' : 'S') : (decimal >= 0 ? 'E' : 'W');
    return `${Math.abs(degrees)}°${minutes}'${seconds}" ${direction}`;
  };
  
 if (map){
  map.on('mousemove', (e) => {
    const lat = e.lngLat.lat;
    const lng = e.lngLat.lng;

    const latDMS = toDMS(lat,"lat");
    const lngDMS = toDMS(lng,"lng");

    setHoverLat(latDMS);
    setHoverLng(lngDMS);
  });
}
  
  const renderLayers = (layers, groupKey) => {
    return Object.keys(layers).map((key) => {
      if (typeof layers[key] === 'object' && !Array.isArray(layers[key])) {
        return (
          <div key={key} className="sub-layer-group">
            <div className="sub-layer-group-header" onClick={() => toggleSubLayerVisibility(groupKey, key)}>
              <span className="toggle-icon">{expandedSubLayers[groupKey]?.[key] ? <i className="fa fa-minus" style={{ color: 'blue' }}></i> : <img src={layericon} alt="layer icon" style={{ width: '13px', height: '13px' }} />}</span>
              <span>{key}</span>
            </div>
            {expandedSubLayers[groupKey]?.[key] && (
              <div className="sub-layer-content">
                {renderLayers(layers[key], `${groupKey}.${key}`)}
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div className='layer-combo' key={key}>
            <label className="layer-option">
            <div className="layer-toggler">
    <input
      type="checkbox"
      name={key}
      value={layers[key]}
      onChange={handleCheckboxChange}
      checked={!!checkedLayers[key]} // Bind the checkbox state to checkedLayers
     
    />
    <span className="layer-text">{key}</span>
  </div>

    
                <>
               
                
                {checkedLayers[key] && (
                <button className="zoom-to-layer-btn" onClick={() => ZoomToLayer(layers[key])}>
                  <i className="fa fa-search-plus"></i> Zoom to layer
                </button>
              )}
                </>
            
            </label>
          </div>
        );
      }
    });
  };


  return (
    <div className="dashboard">
   <div className={`layer-panel ${showLayerPanel ? '' : 'collapsed'}`}>
        <div className="aerodrome">
          <h5>Aerodrome *</h5>
          <select className="select-box" value={selectedAerodrome} onChange={handleSelectClick}>
            <option value="New Delhi">IGI Aerodrome</option>
            <option value="Mumbai">Santa Cruz Aerodrome</option>
          </select>
        </div>
        <div className="layers">
          <h5>Overlay Layers *</h5>
          <div className="scrollable-panel">
            {Object.keys(selectedLayers).map((layerGroup, index) => (
              <div key={index} className="layer-group">
                <div className="layer-group-header" onClick={() => toggleLayerVisibility(layerGroup)}>
                  <span className="toggle-icon">
                    {expandedLayers[layerGroup] ? <i className="fa fa-minus" style={{ color: 'blue' }}></i> : (
                      <img src={layericon} alt="layer icon" style={{ width: '13px', height: '13px' }} />
                    )}
                  </span>
                  <span>{layerGroup}</span>
                </div>
                {expandedLayers[layerGroup] && (
                  <div className="layer-group-content">
                    {renderLayers(selectedLayers[layerGroup], layerGroup)}
                  </div>
                )}
              </div>
            ))}
            {selectedAerodrome === 'New Delhi' && activeBaselayer === 'satellite' && delhiData.metadata && (
            <div className="metadata">
              <strong>Metadata of Satellite Image (New Delhi)</strong>
              <div className="metadata-content">
                {Object.entries(delhiData.metadata).map(([key, value]) => (
                  <div key={key}>
                    <strong>{key}:</strong> {typeof value === 'object' ? JSON.stringify(value) : value}
                  </div>
                ))}
              </div>
            </div>
          )}
              {selectedAerodrome === 'Mumbai' && activeBaselayer === 'satellite' && mumbaiData.metadata && (
            <div className="metadata">
              <strong>Metadata of Satellite Image (Mumbai)</strong>
              <div className="metadata-content">
                {Object.entries(mumbaiData.metadata).map(([key, value]) => (
                  <div key={key}>
                    <strong>{key}:</strong> {typeof value === 'object' ? JSON.stringify(value) : value}
                  </div>
                ))}
              </div>
            </div>
          )}
          </div>
        </div>
     
      </div>
      <div ref={mapPanelRef}  className={`map-panel ${showLayerPanel ? '' : 'expanded'}`}>
        <div className="lat-lon">
          <button className="search-button" onClick={() => setShowSearchForm(!showSearchForm)}>
            <i className={`fa ${showSearchForm ? 'fa-times' : 'fa-search'}`}></i>
          </button>
        </div>

        {showSearchForm && (
          <div className="search-form">
            <div className='input-part'> 
            <span>Latitude</span>
            <input type="text" placeholder="Degrees" value={latDeg} onChange={(e) => setLatDeg(e.target.value)} />
            <input type="text" placeholder="Minutes" value={latMin} onChange={(e) => setLatMin(e.target.value)} />
            <input type="text" placeholder="Seconds" value={latSec} onChange={(e) => setLatSec(e.target.value)} />
            </div>
           <div  className='input-part' > 
           <span>Longitude</span>
            <input type="text" placeholder="Degrees" value={lonDeg} onChange={(e) => setLonDeg(e.target.value)} />
            <input type="text" placeholder="Minutes" value={lonMin} onChange={(e) => setLonMin(e.target.value)} />
            <input type="text" placeholder="Seconds" value={lonSec} onChange={(e) => setLonSec(e.target.value)} />
            </div>
            <button onClick={handleShowPoint}>Show</button>
          </div>
        )}
             <div className='baselayer_toggle'>
             <button  title='No BaseLayer'
        className={activeBaselayer === 'no_basemap' ? 'active' : ''}
        onClick={() => handleBaselayerToggle('no_basemap')}
      >
        <img src={no_basemap} alt="no Baselayer" />
      </button>
      <button  title='switch to street baselayer'
        className={activeBaselayer === 'street' ? 'active' : ''}
        onClick={() => handleBaselayerToggle('street')}
      >
        <img src={street} alt="Street Baselayer" />
      </button>
      <button 
  title={selectedAerodrome === 'New Delhi' ? 'Basemap_Pleiades_imagery' : 'Cartosat-3 Mx satellite imagery'}



        className={activeBaselayer === 'satellite' ? 'active' : ''}
        onClick={() => handleBaselayerToggle('satellite')}
      >
        <img src={satellite} alt="Satellite Baselayer" />
      </button>
      </div>
      <div className='mouse-cursor'>
       
      <button
        title='Pan Map'
        className={`custom-button-icon pan-icon-button ${activeButton === 'pan' ? 'pan-active ' : ''}`}
        onClick={() => handleButtonClick('pan')}
      >
        <img src={PalmIcon} alt="Palm Icon" />
      </button>
      <button
        title='View Attributes'
        className={`custom-button-icon info-icon-button ${activeButton === 'info' ? 'info-active ' : ''}`}
        onClick={() => handleButtonClick('info')}
      >
        <img src={InfoIcon} alt="Info Icon" />
      </button>
      <button 
        className='custom-button-icon'
        onClick={() => handleClearLayers()}
        >
       {Object.keys(checkedLayers).length !== 0 && (
        <img title='Clear Selected Layers' src={ClearLayerIcon} alt="Clear Selected Layers" />
      )}
     
        </button>
   
    </div>
    <div className='mouse-cursor-toggler'>
        <button title="Close Layer Panel" className="custom-button-icon-toggler" onClick={toggleLayerPanel}>
          {( showLayerPanel &&
        <img title='Hide Layer Panel' src={LeftArrow} alt="Hide Layer Panel" />
          )}
             {( !showLayerPanel &&
        <img title='Show Layer Panel' src={RightArrow} alt="Show Layer Panel" />
          )}
      </button>
      </div>

      <div className="hover-coordinates">
        <div>
          <strong>Latitude:</strong>{hoverlat}
        </div>
        <div>
          <strong>Longitude:</strong> {hoverlng}
        </div>
      </div>
     
      </div>
    </div>
  );
}

export default Dashboard;
